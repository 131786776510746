import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import ScreenImg from "./assets/images/common-screen.png"
import FingerprintImg from "./assets/images/fingerprint.png";
import ClockImg from "./assets/images/clock.png";
import SecretsImg from "./assets/images/secrets.png";
import DataImg from "./assets/images/data.png";
import CloudImg from "./assets/images/cloud.png";
import MailImg from "./assets/images/mail.png";
import SkulImg from "./assets/images/skul.png";
import Audio from "./components/Audio";
import { useNavigate } from 'react-router-dom';

const Lobby = () => {
    const [play_audio,set_play_audio] = useState(undefined);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // Optional: smooth scroll animation
        });
    },[]);

    const lobby_redirect = (redirect) => {
        set_play_audio('sfx');
        setTimeout(function(){
            navigate(redirect);
        },100);
    }

    return(
        <>
            <Audio play_audio={play_audio} set_play_audio={set_play_audio}/>
            <div className="main_container">
                <div className="lobby_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_img">
                        <img src={ScreenImg} alt="ScreenImg"/>
                    </div>
                    <div className="screen_scroll">
                        <div className="lobby_buttons_wrap">
                            <div className="lobby_buttons_row">
                                <a className="lobby_button" onClick={() => {lobby_redirect('/user')}}>
                                    <span className="icon">
                                        <img src={FingerprintImg} alt="SECRET USER"/>
                                    </span>
                                    <span className="label">SECRET USER</span>
                                </a>
                                <a to="/clock" className="lobby_button"  onClick={() => {lobby_redirect('/clock')}}>
                                    <span className="icon">
                                        <img src={ClockImg} alt="SECRET CLOCK"/>
                                    </span>
                                    <span className="label">SECRET CLOCK</span>
                                </a>
                                <a to="/secrets" className="lobby_button"  onClick={() => {lobby_redirect('/secrets')}}>
                                    <span className="icon">
                                        <img src={SecretsImg} alt="DEEPER SECRETS"/>
                                    </span>
                                    <span className="label">DEEPER SECRETS</span>
                                </a>
                                <a to="/data" className="lobby_button"  onClick={() => {lobby_redirect('/data')}}>
                                    <span className="icon">
                                        <img src={DataImg} alt="SECRET DATA"/>
                                    </span>
                                    <span className="label">SECRET DATA</span>
                                </a>
                                <a to="/cloud" className="lobby_button"  onClick={() => {lobby_redirect('/cloud')}}>
                                    <span className="icon">
                                        <img src={CloudImg} alt="SECRET CLOUD"/>
                                    </span>
                                    <span className="label">SECRET CLOUD</span>
                                </a>
                                <a to="/mail" className="lobby_button"  onClick={() => {lobby_redirect('/mail')}}>
                                    <span className="icon">
                                        <img src={MailImg} alt="SECRET MAIL"/>
                                    </span>
                                    <span className="label">SECRET MAIL</span>
                                </a>
                                <a to="/hacker-terminal" className="lobby_button"  onClick={() => {lobby_redirect('/hacker-terminal')}}>
                                    <span className="icon">
                                        <img src={SkulImg} alt="HACKER TERMINAL"/>
                                    </span>
                                    <span className="label">HACKER TERMINAL</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Lobby;