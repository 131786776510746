// Save data to session storage
export const saveToSessionStorage = (key, value) => {
    try {
        const serializedValue = JSON.stringify(value);
        sessionStorage.setItem(key, serializedValue);
    } catch (error) {
        console.error("Error saving to session storage", error);
    }
};
  
// Load data from session storage
export const loadFromSessionStorage = (key) => {
    try {
        const serializedValue = sessionStorage.getItem(key);
        if (serializedValue === null) {
        return undefined;
        }
        return JSON.parse(serializedValue);
    } catch (error) {
        console.error("Error loading from session storage", error);
        return undefined;
    }
};
  
// Remove data from session storage
export const removeFromSessionStorage = (key) => {
    try {
        sessionStorage.removeItem(key);
    } catch (error) {
        console.error("Error removing from session storage", error);
    }
};
