import React,{useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import ScreenImg from "../assets/images/common-screen.png";
import SkulImg from "../assets/images/skul.png";
import SkulSHImg from "../assets/images/skul-sh.png";
import Audio from "../components/Audio";
import Video from "../assets/video/TITLE-END-FINAL.mp4";
const StageFour = () => {
    return (
        <>
           <div className="main_container">
                <div className="hacker_screen common_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_scroll">
                        
                        <div className="screen_header">
                            <Link to="/lobby" className="back_btn">&lt;&lt;back</Link>
                            <div className="screen_header_logo">
                                <img src={SkulSHImg} alt="SkulImg" />
                            </div>
                        </div>
                        <div className="video_box">
                            <video autoPlay playsInline loop muted>
                                <source src={Video} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StageFour;