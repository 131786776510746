import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Video from "./assets/video/INTRO-FINAL.mp4";
import ScreenImg from "./assets/images/common-screen.png"

const IntroScreen = () => {
    const videoRef = useRef(null);
    const [video_status,set_video_status] = useState(undefined);
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // Optional: smooth scroll animation
        });
        
        const videoElement = videoRef.current;
        const handleVideoEnd = () => {
            set_video_status('end');
        };

        const handleVideoStarted = () => {
            set_video_status('start');
        };

        if (videoElement) {
            videoElement.addEventListener('ended', handleVideoEnd);
            // videoElement.addEventListener('play', handleVideoStarted);
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener('ended', handleVideoEnd);
            }
        };
    }, []);

    useEffect(() => {
    },[video_status]);
   

    return(
        <>
            <div className="main_container">
                <div className="intro_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_img">
                        <img src={ScreenImg} alt="ScreenImg"/>
                    </div>
                    <div className="screen_scroll">
                        <div className="video_box">
                            <video ref={videoRef} autoPlay playsInline>
                                <source src={Video} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        {
                            video_status == 'end' ?
                                (<div className="text-center">
                                    <Link className="continue_btn" to="/lobby">CONTINUE &gt;&gt;</Link>
                                </div>)
                            : null
                        }
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default IntroScreen;