import React, {useEffect} from 'react';

const NumberKeyboard = (props) => {
    useEffect(() => {
    },[props]);
    return(
        <div className="number_keyboard">
            <button className="number_key" onClick={() => props.update_keyboard('number',1)}>1</button>
            <button className="number_key" onClick={() => props.update_keyboard('number',2)}>2</button>
            <button className="number_key" onClick={() => props.update_keyboard('number',3)}>3</button>
            <button className="number_key" onClick={() => props.update_keyboard('number',4)}>4</button>
            <button className="number_key" onClick={() => props.update_keyboard('number',5)}>5</button>
            <button className="number_key" onClick={() => props.update_keyboard('number',6)}>6</button>
            <button className="number_key" onClick={() => props.update_keyboard('number',7)}>7</button>
            <button className="number_key" onClick={() => props.update_keyboard('number',8)}>8</button>
            <button className="number_key delete" onClick={() => props.update_keyboard('delete','')}>&lt;</button>
            <button className="number_key" onClick={() => props.update_keyboard('number',9)}>9</button>
            <button className="number_key" onClick={() => props.update_keyboard('number',0)}>0</button>
            <button className="number_key" onClick={() => props.update_keyboard('number','#')}>#</button>
        </div>
    )
}

export default NumberKeyboard;