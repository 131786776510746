import React, {useEffect, useState, useRef} from 'react';
import correctAudio from '../assets/audio/correctSound.mp3';
import incorrectAudio from '../assets/audio/incorrectSound.mp3';
import sfxAudio from '../assets/audio/sfxAudio.wav';

const Audio = (props) => {
    const correctAudioRef = useRef(null);
    const incorrectAudioRef = useRef(null);
    const sfxAudioRef = useRef(null);
    useEffect(() => {
    },[props]);
    useEffect(() => {
        if(props.play_audio !== undefined && props.play_audio == 'correct')
        {
            correctAudioRef.current.play();
            props.set_play_audio(undefined);
        }
        if(props.play_audio !== undefined && props.play_audio == 'incorrect')
        {
            incorrectAudioRef.current.play();
            props.set_play_audio(undefined);
        }
        if(props.play_audio !== undefined && props.play_audio == 'sfx')
        {
            sfxAudioRef.current.play();
            props.set_play_audio(undefined);
        }
    },[props]);

    const sfxhandleAudioEnded = () => {
        props.set_play_audio(undefined);
    }

    return(
        <>
            <audio ref={correctAudioRef} src={correctAudio} />
            <audio ref={incorrectAudioRef} src={incorrectAudio} />
            <audio ref={sfxAudioRef} src={sfxAudio} onEnded={sfxhandleAudioEnded}/>
        </>
    )
}

export default Audio;