import React, {useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import ScreenImg from "./assets/images/common-screen.png"
import ClockImg from "./assets/images/clock.png";
import SuccessImg from "./assets/images/success-img.png";
import EnglishKeyboard from './components/EnglishKeyboard';
import Audio from "./components/Audio";
import {saveToSessionStorage,loadFromSessionStorage,removeFromSessionStorage} from './helpers.js';

const Clock = () => {

    const [active_input,set_active_input] = useState('username');
    const [username,set_username] = useState('');
    const [password,set_password] = useState('');
    const [correct_answer,set_correct_answer] = useState(undefined);
    const [play_audio,set_play_audio] = useState(undefined);
    const correct_username = 'dcom';
    const correct_password = 'burny';
    let setTimer;
    const usernameRef = useRef('');
    const passwordRef = useRef('');

    const input_focus = (inputref) => {
        const x = window.scrollX;
        const y = window.scrollY;
        
        inputref.current.focus({ preventScroll: true });
        inputref.current.setSelectionRange(inputref.current.value.toString().length,inputref.current.value.toString().length);

        window.scrollTo(x, y);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // Optional: smooth scroll animation
        });
        let sap_clock_username = loadFromSessionStorage('sap_clock_username');
        let sap_clock_password = loadFromSessionStorage('sap_clock_password');
        if(sap_clock_username == correct_username && sap_clock_password == correct_password)
        {
            set_correct_answer(true);
        }
    },[]);

    useEffect(() => {
        
    },[correct_answer]);


    const updateKeyboard = (key,text='') => {
        clearTimeout(setTimer);
        set_correct_answer(undefined);
        set_play_audio(undefined);
        switch (key) {
            case 'text':
                switch (active_input) {
                    case 'username':
                        set_username(username+text)
                        input_focus(usernameRef);
                        break;
                    case 'password':
                        set_password(password+text)
                        input_focus(passwordRef);

                        break;
                    default:
                        break;
                }
            break;
            case 'delete':
                switch (active_input) {
                    case 'username':
                        let newusername = username.split('');
                        newusername.pop();
                        set_username(newusername.join(''));
                        input_focus(usernameRef);
                        break;
                    case 'password':
                        let newpassword = password.split('');
                        newpassword.pop();
                        set_password(newpassword.join(''));
                        input_focus(passwordRef);
                        break;
                    default:
                        break;
                }
               
            break;
        
            default:
                break;
        }
    }

    const updateAnswer = () => {
        if(username.toLowerCase() == correct_username && password.toLowerCase() == correct_password)
        {
            set_correct_answer(true);
            set_play_audio('correct');
            saveToSessionStorage('sap_clock_username',correct_username);
            saveToSessionStorage('sap_clock_password',correct_password);
        }else{
            set_correct_answer(false);
            set_play_audio('incorrect');
            removeFromSessionStorage('sap_clock_username');
            removeFromSessionStorage('sap_clock_password');
        }
    }
    
    return(
        <>
            <Audio play_audio={play_audio} set_play_audio={set_play_audio}/>

            <div className="main_container">
                <div className="clock_screen common_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_img">
                        <img src={ScreenImg} alt="ScreenImg"/>
                    </div>
                    <div className="screen_scroll">
                        <div className="screen_header">
                            <Link to="/lobby" className="back_btn">&lt;&lt;back</Link>
                            <div className="screen_header_logo">
                                <img src={ClockImg} alt="Clock" />
                            </div>
                        </div>
                        <div className="screen_content text-center">
                            <p>To access Secret Clock input username and password</p>
                        </div>
                        <div className="qwerty_keyboard_wrapper">
                            {
                                correct_answer == false || correct_answer == undefined ?
                                    (
                                    <>
                                        <div className="input_wrapper">
                                            <input inputmode="none" type="text" name="number" value={username} className={correct_answer == true ? 'form-control success' : (correct_answer == false ? 'form-control error' : 'form-control')} placeholder="Username" onFocus={() => {set_active_input('username')}} ref={usernameRef} onKeyDown={(e) => e.preventDefault()} onClick={(e) => {e.preventDefault();input_focus(usernameRef);}} autoComplete="off" onChange={() => {}}/>
                                            <input inputmode="none" type="text" name="number" value={password} className={correct_answer == true ? 'form-control success' : (correct_answer == false ? 'form-control error' : 'form-control')} placeholder="Password" onFocus={() => {set_active_input('password')}} ref={passwordRef} onKeyDown={(e) => e.preventDefault()} onClick={(e) => {e.preventDefault();input_focus(passwordRef);}} autoComplete="off" onChange={() => {}}/>
                                            <button className="send_btn" onClick={() => updateAnswer()}>send</button>
                                        </div>
                                        <EnglishKeyboard updatekeyboard={updateKeyboard}/>
                                    </>
                                    )
                                : null
                            }
                            
                            
                            {
                                correct_answer == true ?
                                    (<div className="form_msg form_success_msg">
                                        <p><strong>Username and password are correct</strong></p>
                                        <img src={SuccessImg} alt="SuccessImg"/>
                                    </div>)
                                : null

                            }
                            {
                                correct_answer == false ?
                                    (<div className="form_msg form_error_msg"><p>USERNAME AND PASSWORD INCORRECT</p></div>)
                                : null
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Clock;