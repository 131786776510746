import React, {useEffect} from 'react';

const EnglishKeyboard = (props) => {

    useEffect(() => {
    },[props]);

    return(
        <div className="qwerty_keyboard">
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','Q')}>Q</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','W')}>W</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','E')}>E</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','R')}>R</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','T')}>T</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','Y')}>Y</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','U')}>U</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','I')}>I</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','O')}>O</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','P')}>P</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','A')}>A</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','S')}>S</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','D')}>D</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','F')}>F</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','G')}>G</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','H')}>H</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','J')}>J</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','K')}>K</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','L')}>L</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','Z')}>Z</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','X')}>X</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','C')}>C</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','V')}>V</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','B')}>B</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','N')}>N</button>
            <button className="qwerty_key" onClick={() => props.updatekeyboard('text','M')}>M</button>
            <button className="qwerty_key delete" onClick={() => props.updatekeyboard('delete','')}>&lt;</button>
        </div>
    )
}

export default EnglishKeyboard;