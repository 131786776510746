import React,{useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import ScreenImg from "./assets/images/common-screen.png";
import SkulImg from "./assets/images/skul.png";
import StageOne from "./hacker_terminal_stages/stageOne";
import StageTwo from "./hacker_terminal_stages/stageTwo";
import StageThree from "./hacker_terminal_stages/stageThree";
import StageFour from "./hacker_terminal_stages/stageFour";
import {saveToSessionStorage,loadFromSessionStorage,removeFromSessionStorage} from './helpers.js';

const HackerTerminal = () => {  

    const [stage,set_stage] = useState(1); 
    const [corrct_value,set_corrct_value] = useState(false); 
    const [page_loaded,set_page_loaded] = useState(false); 
    useEffect(() => {
        
       

    });

    useEffect(() => {
        let stage1 = loadFromSessionStorage('sap_hacker_stage1_answer');
        let stage2 = loadFromSessionStorage('sap_hacker_stage2_answer');
        let stage3 = loadFromSessionStorage('sap_hacker_stage3_answer');
        if( 
            stage1 !== undefined && stage1 !== null && stage1 == 1471 &&
            stage2 !== undefined && stage2 !== null && stage2 == 728213 &&
            stage3 !== undefined && stage3 !== null && stage3 == '01110010' 
        ){
            if(page_loaded == true)
            {
                set_stage(3);
                set_corrct_value(true);
            }
        }
    },[page_loaded]);

    return(
        <>
            { stage == 1 ? <StageOne stage={stage} set_stage={set_stage} set_page_loaded={set_page_loaded}/> : null }
            { stage == 2 ? <StageTwo stage={stage} set_stage={set_stage} set_page_loaded={set_page_loaded}/> : null }
            { stage == 3 ? <StageThree stage={stage} set_stage={set_stage} set_page_loaded={set_page_loaded} corrctValue={corrct_value}/> : null }
            { stage == 4 ? <StageFour stage={stage} set_stage={set_stage} set_page_loaded={set_page_loaded}/> : null }                    
        </>
    )
}

export default HackerTerminal;