import React,{useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import ScreenImg from "./assets/images/common-screen.png"
import FingerprintImg from "./assets/images/fingerprint.png";
import FingerprintSHImg from "./assets/images/fingerprint-sh.png";
import UserImg from "./assets/images/user-img.png";
import NumberKeyboard from "./components/NumberKeyboard";
import { useNavigate } from 'react-router-dom';
import Audio from "./components/Audio";
import {saveToSessionStorage,loadFromSessionStorage,removeFromSessionStorage} from './helpers.js';

const User = () => {   
    const inputRef = useRef(''); 
    const navigate = useNavigate();
    const [keyboardNumber,setKeyboardNumber] = useState('');
    const [correct_answer,set_correct_answer] = useState(undefined);
    const [play_audio,set_play_audio] = useState(undefined);
    let setTimer;
    const get_result_time = 500;
    const correct_code = 4305;
    const [cached_ans_is_correct,set_cached_ans_is_correct] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // Optional: smooth scroll animation
        });
        if(loadFromSessionStorage('sap_user_answer') !== null && loadFromSessionStorage('sap_user_answer') !== undefined && loadFromSessionStorage('sap_user_answer') == correct_code)
        {
            set_cached_ans_is_correct(true);
            set_correct_answer(true);
            setKeyboardNumber(correct_code);
            set_correct_answer(true);
        }
        

    },[]);

    useEffect(() => {
    },[correct_answer]);

    const input_focus = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        
        inputRef.current.focus({ preventScroll: true });
        inputRef.current.setSelectionRange(inputRef.current.value.toString().length,inputRef.current.value.toString().length);

        // window.scrollTo(x, y);
    }

    useEffect(() => {
        input_focus();
        clearTimeout(setTimer);
        // set_correct_answer(undefined);
        set_play_audio(undefined);
        if(keyboardNumber.length > 0)
        {
            setTimer = setTimeout(function(){
                
                if(keyboardNumber == correct_code)
                {
                    saveToSessionStorage('sap_user_answer',correct_code);
                    set_correct_answer(true);
                    set_play_audio('correct');
                    //correctAudioRef.current.play();
                    setTimeout(function(){
                        //correctAudioRef.current.pause();
                        //correctAudioRef.currentTime = 0;
                        // navigate('/lobby');
                    },500);
                }else{

                    if(keyboardNumber.toString().length >= correct_code.toString().length)
                    {
                        removeFromSessionStorage('sap_user_answer');
                        set_correct_answer(false);
                        set_play_audio('incorrect');
                        //incorrectAudioRef.current.play();
                    }
                }

            },get_result_time);
        }
    },[keyboardNumber]);
    


    const updateKeyboard = (key,number='') => {
        clearTimeout(setTimer);
        set_correct_answer(undefined);
        set_play_audio(undefined);
        switch (key) {
            case 'number':
                setKeyboardNumber(keyboardNumber.toString()+number)

            break;

            case 'delete':
                let newkeyboardNumber = keyboardNumber.split('');
                newkeyboardNumber.pop();
                console.log(newkeyboardNumber);
                setKeyboardNumber(newkeyboardNumber.join(''));
            break;
        
            default:
                break;
        }
    }
  

    return(
        <>
            <Audio play_audio={play_audio} set_play_audio={set_play_audio}/>
            <div className="main_container">
                <div className="user_screen common_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_img">
                        <img src={ScreenImg} alt="ScreenImg"/>
                    </div>
                    <div className="screen_scroll">
                        <div className="screen_header">
                            <Link to="/lobby" className="back_btn">&lt;&lt;back</Link>
                            <div className="screen_header_logo">
                                <img src={FingerprintSHImg} alt="User" />
                            </div>
                        </div>
                        <div className="screen_content">
                            <p>Some info we gathered about CX Ops & Delivery They are providing DevOps services to several Engineering teams dealing with Data & AI Products.<br/> Data Exchange (formerly CDF) Provides application developers and data scientist with a shared, secure and reliable access to SAP Business information, using common data models and ready to use integration packages, eliminating the need of the AI, Industry and Customer Experience solutions to invest in point-to-point integration.</p>
                        </div>
                        <div className="user_screen_text">
                            <div className="text">
                                <p>To access secret username input four digit code:</p>
                            </div>
                            <div className="img">
                                <img src={UserImg} alt="toolkit"/>
                            </div>
                        </div>
                        <div className="number_keyboard_wrapper">
                            <div className="input_wrapper">
                                <input inputmode="none" ref={inputRef} type="text" name="number" value={keyboardNumber} className={correct_answer == true ? 'form-control success' : (correct_answer == false ? 'form-control error' : 'form-control')} onKeyDown={(e) => {setKeyboardNumber(keyboardNumber)}} onClick={(e) => {e.preventDefault();input_focus();}} onChange={() => {} } autoComplete="off"/>
                            </div>
                            <NumberKeyboard update_keyboard={updateKeyboard}/>
                            {
                                correct_answer == true 
                                    ? 
                                    (<div className="form_msg form_success_msg">
                                        <p><strong>Code Verified successfully</strong></p>
                                        <p>Username for secret clock is dcom</p>
                                    </div>)
                                    : null
                            }
                            {
                                correct_answer == false
                                ?
                                <div className="form_msg form_error_msg"><p>Code Incorrect</p></div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default User;