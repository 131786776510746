import React,{useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import ScreenImg from "../assets/images/common-screen.png";
import SkulImg from "../assets/images/skul.png";
import SkulSHImg from "../assets/images/skul-sh.png";
import Audio from "../components/Audio";
import {saveToSessionStorage,loadFromSessionStorage,removeFromSessionStorage} from '../helpers.js';

const StageThree = (props) => {


    const radioRef1 = useRef(null);
    const radioRef2 = useRef(null);
    const radioRef3 = useRef(null);
    const radioRef4 = useRef(null);
    const radioRef5 = useRef(null);
    const radioRef6 = useRef(null);
    const radioRef7 = useRef(null);
    const radioRef8 = useRef(null);

    const [radio1_val,set_radio1_val] = useState(undefined);
    const [radio2_val,set_radio2_val] = useState(undefined);
    const [radio3_val,set_radio3_val] = useState(undefined);
    const [radio4_val,set_radio4_val] = useState(undefined);
    const [radio5_val,set_radio5_val] = useState(undefined);
    const [radio6_val,set_radio6_val] = useState(undefined);
    const [radio7_val,set_radio7_val] = useState(undefined);
    const [radio8_val,set_radio8_val] = useState(undefined);

    const [user_code,set_user_code] = useState('00000000');
    const correct_code = '01110010';
    const [correct_answer,set_correct_answer] = useState(undefined);
    const [play_audio,set_play_audio] = useState(undefined);
    const [radio_clicked,set_radio_clicked] = useState(false);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // Optional: smooth scroll animation
        });
        if(props.corrctValue == true)
        {
            set_correct_answer(true);
            set_user_code(correct_code);
            set_radio1_val(0);
            set_radio2_val(1);
            set_radio3_val(1);
            set_radio4_val(1);
            set_radio5_val(0);
            set_radio6_val(0);
            set_radio7_val(1);
            set_radio8_val(0);
            document.querySelector('.toggle_switch_wrapper input[name="radio-a"][value="0"]').checked = true;
            document.querySelector('.toggle_switch_wrapper input[name="radio-b"][value="1"]').checked = true;
            document.querySelector('.toggle_switch_wrapper input[name="radio-c"][value="1"]').checked = true;
            document.querySelector('.toggle_switch_wrapper input[name="radio-d"][value="1"]').checked = true;
            document.querySelector('.toggle_switch_wrapper input[name="radio-e"][value="0"]').checked = true;
            document.querySelector('.toggle_switch_wrapper input[name="radio-f"][value="0"]').checked = true;
            document.querySelector('.toggle_switch_wrapper input[name="radio-g"][value="1"]').checked = true;
            document.querySelector('.toggle_switch_wrapper input[name="radio-h"][value="0"]').checked = true;

        }
    },[]);

    useEffect(() => {
        
    },[user_code]); 

    const [selectedValue, setSelectedValue] = useState('');


    const getResult = (e) => {
        e.preventDefault();
        set_radio_clicked(true);
    }

    useEffect(() => {
        let new_value = '';
        let radio_a = radio1_val == undefined ? '' : (radio1_val == true ? 1 : 0);
        let radio_b = radio2_val == undefined ? '' : (radio2_val == true ? 1 : 0);
        let radio_c = radio3_val == undefined ? '' : (radio3_val == true ? 1 : 0);
        let radio_d = radio4_val == undefined ? '' : (radio4_val == true ? 1 : 0);
        let radio_e = radio5_val == undefined ? '' : (radio5_val == true ? 1 : 0);
        let radio_f = radio6_val == undefined ? '' : (radio6_val == true ? 1 : 0);
        let radio_g = radio7_val == undefined ? '' : (radio7_val == true ? 1 : 0);
        let radio_h = radio8_val == undefined ? '' : (radio8_val == true ? 1 : 0);
        new_value = radio_a.toString()+radio_b.toString()+radio_c.toString()+radio_d.toString()+radio_e.toString()+radio_f.toString()+radio_g.toString()+radio_h.toString();
        // new_value = radio_a+radio_b+radio_c+radio_d+radio_e+radio_f+radio_g+radio_h;
        set_user_code(new_value);
        if(new_value == correct_code)
        {
            set_correct_answer(true);
            if(radio_clicked == true)
            {
                set_play_audio('correct');
            }
            saveToSessionStorage('sap_hacker_stage3_answer',correct_code);
            if(radio_clicked == true)
            {
                setTimeout(function(){
                    props.set_stage(4);
                },1500);
            }
        }else{
            removeFromSessionStorage('sap_hacker_stage3_answer');
            set_correct_answer(false);
            if(radio_clicked == true)
            {
                // set_play_audio('incorrect');
            }
        }
    },[radio1_val,radio2_val,radio3_val,radio4_val,radio5_val,radio6_val,radio7_val,radio8_val]);

    return (
        <>
            <Audio play_audio={play_audio} set_play_audio={set_play_audio}/>
            <div className="main_container">
                <div className="hacker_screen common_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_img">
                        <img src={ScreenImg} alt="ScreenImg"/>
                    </div>
                    <div className="screen_scroll">
                        <div className="screen_header">
                            <Link to="/lobby" className="back_btn">&lt;&lt;back</Link>
                            <div className="screen_header_logo">
                                <img src={SkulSHImg} alt="SkulImg" />
                            </div>
                        </div>

                        <div className="screen_content text-center step_three">
                            <p>Congratulations, you are inside the hacker terminal. To stop the cyber attack and delete all stolen data configre pass/fail switches</p>
                        </div>
                        
                        {/* Step - 3 */}
                        <div className="toggle_switch_wrapper">
                            <div className="toggle_switch_row">
                                
                                <div className="toggle_switch">
                                    <input type="radio" name="radio-a" checked={radio1_val == true ? true : false} value='1' ref={radioRef1} onClick={(e) => {(radio1_val == undefined ? set_radio1_val(1): set_radio1_val(!radio1_val));getResult(e)}} onChange={(e) => getResult(e)}/>
                                    <input type="radio" name="radio-a" checked={radio1_val == undefined ? true : false} value="" ref={radioRef1} onClick={(e) => {(radio1_val == undefined ? set_radio1_val(1): set_radio1_val(!radio1_val));getResult(e)}} onChange={(e) => {getResult(e)}} />
                                    <input type="radio" name="radio-a" checked={radio1_val == false ? true : false} value='0' ref={radioRef1} onClick={(e) => {(radio1_val == undefined ? set_radio1_val(1): set_radio1_val(!radio1_val));getResult(e)}} onChange={(e) => getResult(e)}/>                                    
                                    <span className="toggle_switch_btn"></span>
                                </div>
                                <div className="toggle_switch">
                                    <input type="radio" name="radio-b" checked={radio2_val == true ? true : false} value='1' ref={radioRef2} onClick={(e) => {(radio2_val == undefined ? set_radio2_val(1): set_radio2_val(!radio2_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <input type="radio" name="radio-b" checked={radio2_val == undefined ? true : false} value="" ref={radioRef2} onClick={(e) => {(radio2_val == undefined ? set_radio2_val(1): set_radio2_val(!radio2_val));getResult(e)}} onChange={(e) => getResult(e)}/>
                                    <input type="radio" name="radio-b" checked={radio2_val == false ? true : false} value='0' ref={radioRef2} onClick={(e) => {(radio2_val == undefined ? set_radio2_val(1): set_radio2_val(!radio2_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <span className="toggle_switch_btn"></span>
                                </div>
                                <div className="toggle_switch">
                                    <input type="radio" name="radio-c" checked={radio3_val == true ? true : false} value={1} ref={radioRef3} onClick={(e) => {(radio3_val == undefined ? set_radio3_val(1): set_radio3_val(!radio3_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <input type="radio" name="radio-c" checked={radio3_val == undefined ? true : false} value="" ref={radioRef3} onClick={(e) => {(radio3_val == undefined ? set_radio3_val(1): set_radio3_val(!radio3_val));getResult(e)}} onChange={(e) => getResult(e)}/>
                                    <input type="radio" name="radio-c" checked={radio3_val == false ? true : false} value={0} ref={radioRef3} onClick={(e) => {(radio3_val == undefined ? set_radio3_val(1): set_radio3_val(!radio3_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <span className="toggle_switch_btn"></span>
                                </div>
                                <div className="toggle_switch">
                                    <input type="radio" name="radio-d" checked={radio4_val == true ? true : false} value={1} ref={radioRef4} onClick={(e) => {(radio4_val == undefined ? set_radio4_val(1): set_radio4_val(!radio4_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <input type="radio" name="radio-d" checked={radio4_val == undefined ? true : false} value="" ref={radioRef4} onClick={(e) => {(radio4_val == undefined ? set_radio4_val(1): set_radio4_val(!radio4_val));getResult(e)}} onChange={(e) => getResult(e)}/>
                                    <input type="radio" name="radio-d" checked={radio4_val == false ? true : false} value={0} ref={radioRef4} onClick={(e) => {(radio4_val == undefined ? set_radio4_val(1): set_radio4_val(!radio4_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <span className="toggle_switch_btn"></span>
                                </div>
                                <div className="toggle_switch">
                                    <input type="radio" name="radio-e" checked={radio5_val == true ? true : false} value={1} ref={radioRef5} onClick={(e) => {(radio5_val == undefined ? set_radio5_val(1): set_radio5_val(!radio5_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <input type="radio" name="radio-e" checked={radio5_val == undefined ? true : false} value="" ref={radioRef5} onClick={(e) => {(radio5_val == undefined ? set_radio5_val(1): set_radio5_val(!radio5_val));getResult(e)}} onChange={(e) => getResult(e)}/>
                                    <input type="radio" name="radio-e" checked={radio5_val == false ? true : false} value={0} ref={radioRef5} onClick={(e) => {(radio5_val == undefined ? set_radio5_val(1): set_radio5_val(!radio5_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <span className="toggle_switch_btn"></span>
                                </div>
                                <div className="toggle_switch">
                                    <input type="radio" name="radio-f" checked={radio6_val == true ? true : false} value={1} ref={radioRef6} onClick={(e) => {(radio6_val == undefined ? set_radio6_val(1): set_radio6_val(!radio6_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <input type="radio" name="radio-f" checked={radio6_val == undefined ? true : false} value="" ref={radioRef6} onClick={(e) => {(radio6_val == undefined ? set_radio6_val(1): set_radio6_val(!radio6_val));getResult(e)}} onChange={(e) => getResult(e)}/>
                                    <input type="radio" name="radio-f" checked={radio6_val == false ? true : false} value={0} ref={radioRef6} onClick={(e) => {(radio6_val == undefined ? set_radio6_val(1): set_radio6_val(!radio6_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <span className="toggle_switch_btn"></span>
                                </div>
                                <div className="toggle_switch">
                                    <input type="radio" name="radio-g" checked={radio7_val == true ? true : false} value={1} ref={radioRef7} onClick={(e) => {(radio7_val == undefined ? set_radio7_val(1): set_radio7_val(!radio7_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <input type="radio" name="radio-g" checked={radio7_val == undefined ? true : false} value="" ref={radioRef7} onClick={(e) => {(radio7_val == undefined ? set_radio7_val(1): set_radio7_val(!radio7_val));getResult(e)}} onChange={(e) => getResult(e)}/>
                                    <input type="radio" name="radio-g" checked={radio7_val == false ? true : false} value={0} ref={radioRef7} onClick={(e) => {(radio7_val == undefined ? set_radio7_val(1): set_radio7_val(!radio7_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <span className="toggle_switch_btn"></span>
                                </div>
                                <div className="toggle_switch">
                                    <input type="radio" name="radio-h" checked={radio8_val == true ? true : false} value={1} ref={radioRef8} onClick={(e) => {(radio8_val == undefined ? set_radio8_val(1): set_radio8_val(!radio8_val));getResult(e)}} onChange={(e) => getResult(e)}  />
                                    <input type="radio" name="radio-h" checked={radio8_val == undefined ? true : false} value="" ref={radioRef8} onClick={(e) => {(radio8_val == undefined ? set_radio8_val(1): set_radio8_val(!radio8_val));getResult(e)}} onChange={(e) => getResult(e)}/>
                                    <input type="radio" name="radio-h" checked={radio8_val == false ? true : false} value={0} ref={radioRef8} onClick={(e) => {(radio8_val == undefined ? set_radio8_val(1): set_radio8_val(!radio8_val));getResult(e)}} onChange={(e) => getResult(e)} />
                                    <span className="toggle_switch_btn"></span>
                                </div>
                            </div>
                            {
                                correct_answer == true ?
                                    (<div className="form_msg form_success_msg">
                                        <p><strong>Correct sequence</strong></p>
                                    </div>)
                                : null
                            }
                            
                        </div>

                       
                    </div>
                </div>
            </div>
        </>
    )
}

export default StageThree;