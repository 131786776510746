import React from 'react';
import './App.css';
import './Responsive.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import StartScreen from './StartScreen.js';
import IntroScreen from './IntroScreen.js';
import Lobby from './Lobby.js';
import User from './User.js';
import Clock from './Clock.js';
import Secrets from './Secrets.js';
import Data from './Data.js';
import Cloud from './Cloud.js';
import Mail from './Mail.js';
import HackerTerminal from './HackerTerminal.js';
const App =() => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<StartScreen />}/>
        <Route path="/intro" element={<IntroScreen />}/>
        <Route path="/lobby" element={<Lobby />}/>
        <Route path="/user" element={<User />}/>
        <Route path="/clock" element={<Clock />}/>
        <Route path="/secrets" element={<Secrets />}/>
        <Route path="/data" element={<Data />}/>
        <Route path="/cloud" element={<Cloud />}/>
        <Route path="/mail" element={<Mail />}/>
        <Route path="/hacker-terminal" element={<HackerTerminal />}/>
      </Routes>
  </BrowserRouter>
  );
}

export default App;
