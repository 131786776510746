import React,{useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import ScreenImg from "../assets/images/common-screen.png";
import SkulImg from "../assets/images/skul.png";
import SkulSHImg from "../assets/images/skul-sh.png";
import Audio from "../components/Audio";
import NumberKeyboard from "../components/NumberKeyboard";
import { useNavigate } from 'react-router-dom';
import {saveToSessionStorage,loadFromSessionStorage,removeFromSessionStorage} from '../helpers.js';

const StageTwo = (props) => {

    const inputRef = useRef('');
    const navigate = useNavigate();
    const [keyboardNumber,setKeyboardNumber] = useState('');
    const [correct_answer,set_correct_answer] = useState(undefined);
    const [play_audio,set_play_audio] = useState(undefined);
    const [time, setTime] = useState(120); // 120 seconds = 2 minutes
    let setTimer;
    const get_result_time = 500;
    const correct_code = 728213;

    useEffect(() => {
        if (time > 0) {
          const timerId = setInterval(() => {
            setTime(prevTime => prevTime - 1);
          }, 1000);
    
          return () => clearInterval(timerId); // Cleanup interval on component unmount
        }else{
            navigate('/lobby');
        }
    }, [time]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // Optional: smooth scroll animation
        });
        input_focus();
    },[]);

    const input_focus = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        
        inputRef.current.focus({ preventScroll: true });
        inputRef.current.setSelectionRange(inputRef.current.value.toString().length,inputRef.current.value.toString().length);

        window.scrollTo(x, y);
    }

    useEffect(() => {
        input_focus();
        clearTimeout(setTimer);
        set_correct_answer(undefined);
        set_play_audio(undefined);
        if(keyboardNumber.length > 0)
        {
            setTimer = setTimeout(function(){
                if(keyboardNumber == correct_code)
                {
                    set_correct_answer(true);
                    set_play_audio('correct');
                    saveToSessionStorage('sap_hacker_stage2_answer',correct_code);
                    setTimeout(function(){
                        props.set_stage(3);
                    },1500);
                }else{
                    if(keyboardNumber.toString().length >= correct_code.toString().length)
                    {
                        set_correct_answer(false);
                        set_play_audio('incorrect');
                        removeFromSessionStorage('sap_hacker_stage2_answer');
                    }
                }

            },get_result_time);
        }
    },[keyboardNumber]);
    


    const updateKeyboard = (key,number='') => {
        clearTimeout(setTimer);
        set_correct_answer(undefined);
        set_play_audio(undefined);
        switch (key) {
            case 'number':
                setKeyboardNumber(keyboardNumber+number)
            break;

            case 'delete':
                let newkeyboardNumber = keyboardNumber.split('');
                newkeyboardNumber.pop();
                setKeyboardNumber(newkeyboardNumber.join(''));
            break;
        
            default:
                break;
        }
    }

    return (
        <>
            <Audio play_audio={play_audio} set_play_audio={set_play_audio}/>
            <div className="main_container">
                <div className="hacker_screen common_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_img">
                        <img src={ScreenImg} alt="ScreenImg"/>
                    </div>
                    <div className="screen_scroll">
                        <div className="screen_header">
                            <Link to="/lobby" className="back_btn">&lt;&lt;back</Link>
                            <div className="screen_header_logo">
                                <img src={SkulSHImg} alt="SkulImg" />
                            </div>
                        </div>
                        <div className="screen_content text-center step_two">
                            <p>Two Step Verification - Part 2 Timestamp 1:42</p>
                        </div>
                        
                        <div className="number_keyboard_wrapper">
                            <div className="input_wrapper">
                                <input inputmode="none" type="text" name="number" value={keyboardNumber} className={correct_answer == true ? 'form-control success' : (correct_answer == false ? 'form-control error' : 'form-control')} ref={inputRef} onKeyDown={(e) => e.preventDefault()} onClick={(e) => {e.preventDefault();input_focus()}} autoComplete="off" onChange={() => {}}/>
                            </div>
                            <NumberKeyboard update_keyboard={updateKeyboard}/>
                            {
                                correct_answer == true ?
                                    (<div className="form_msg form_success_msg">
                                        <p><strong>Code Verified successfully</strong></p>
                                    </div>)
                                : null
                            }
                            {
                                correct_answer == false ?
                                    (<div className="form_msg form_error_msg"><p>Code Incorrect</p></div>)
                                : null
                            }                           
                        </div>                       
                    </div>
                </div>
            </div>
        </>
    )
}

export default StageTwo;