import React,{useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import ScreenImg from "./assets/images/common-screen.png"
import MailImg from "./assets/images/mail.png";
import MailSHImg from "./assets/images/mail-sh.png";
import ComputerImg from "./assets/images/ComputerImg.png";
import NumberKeyboard from "./components/NumberKeyboard";
import { useNavigate } from 'react-router-dom';
import Audio from "./components/Audio";
import {saveToSessionStorage,loadFromSessionStorage,removeFromSessionStorage} from './helpers.js';

const User = () => {   
    const inputRef = useRef(''); 
    const navigate = useNavigate();
    const [keyboardNumber,setKeyboardNumber] = useState('');
    const [correct_answer,set_correct_answer] = useState(undefined);
    const [play_audio,set_play_audio] = useState(undefined);
    let setTimer;
    const get_result_time = 500;
    const correct_code = 413;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // Optional: smooth scroll animation
        });
        if(loadFromSessionStorage('sap_mail_answer') !== undefined && loadFromSessionStorage('sap_mail_answer') !== null && loadFromSessionStorage('sap_mail_answer') == correct_code)
        {
            set_correct_answer(true);
            setKeyboardNumber(correct_code);   
        }
        input_focus();
    },[]);

    const input_focus = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        
        inputRef.current.focus({ preventScroll: true });
        inputRef.current.setSelectionRange(inputRef.current.value.toString().length,inputRef.current.value.toString().length);

        window.scrollTo(x, y);
    }
    
    useEffect(() => {
        input_focus();
        clearTimeout(setTimer);
        // set_correct_answer(undefined);
        set_play_audio(undefined);
        if(keyboardNumber.length > 0)
        {
            setTimer = setTimeout(function(){
                if(keyboardNumber == correct_code)
                {
                    set_correct_answer(true);
                    if(loadFromSessionStorage('sap_mail_answer') == undefined || loadFromSessionStorage('sap_mail_answer') == null)
                    {
                        set_play_audio('correct');
                    }
                    saveToSessionStorage('sap_mail_answer',correct_code);
                }else{
                    if(keyboardNumber.toString().length >= correct_code.toString().length)
                    {
                        removeFromSessionStorage('sap_mail_answer');
                        set_correct_answer(false);
                        if(loadFromSessionStorage('sap_mail_answer') == undefined || loadFromSessionStorage('sap_mail_answer') == null)
                        {
                            set_play_audio('incorrect');
                        }
                    }
                }

            },get_result_time);
        }
    },[keyboardNumber]);
    


    const updateKeyboard = (key,number='') => {
        clearTimeout(setTimer);
        set_correct_answer(undefined);
        set_play_audio(undefined);
        switch (key) {
            case 'number':
                setKeyboardNumber(keyboardNumber.toString()+number)
            break;

            case 'delete':
                let newkeyboardNumber = keyboardNumber.toString().split('');
                newkeyboardNumber.pop();
                setKeyboardNumber(newkeyboardNumber.join(''));
            break;
        
            default:
                break;
        }
    }
    return(
        <>
            <Audio play_audio={play_audio} set_play_audio={set_play_audio}/>
            <div className="main_container">
                <div className="mail_screen common_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_img">
                        <img src={ScreenImg} alt="ScreenImg"/>
                    </div>
                    <div className="screen_scroll">
                        <div className="screen_header">
                            <Link to="/lobby" className="back_btn">&lt;&lt;back</Link>
                            <div className="screen_header_logo">
                                <img src={MailSHImg} alt="MailImg" />
                            </div>
                        </div>
                        <div className="screen_content">
                            <p>Some info we gathered on CP Portals products: <br/>SAP Build Work Zone empowers IT professionals and line of business experts to easily build and design engaging business sites for employees, partners and customers with intuitive drag-and-drop functionality. It increases user productivity by creating a single access point to applications, processes, information and data on any device, from desktop or mobile. It maximizes team productivity by enabling guided experiences and knowledge sharing. To complete the offering for SAP's central entry point, there is also SAP Start, which is an SAP-managed homepage for SAP Cloud solutions only. SAP Start is not part of the SAP Build brand and portfolio, but it uses SAP Build Work Zone as the technical foundation.</p>
                            <p>To access the hacker terminal insert three digit code:</p>
                        </div>
                        <div className="number_keyboard_wrapper">
                            <div className="input_wrapper">
                                <div className="form_icon_grp">
                                    <img src={ComputerImg} alt="ComputerImg"/>
                                    <input inputmode="none" type="text" name="number" value={keyboardNumber} className={correct_answer == true ? 'form-control success' : (correct_answer == false ? 'form-control error' : 'form-control')} ref={inputRef} ref={inputRef} onKeyDown={(e) => e.preventDefault()} onClick={(e) => {e.preventDefault();input_focus()}} autoComplete="off"/>
                                </div>
                            </div>
                            <NumberKeyboard update_keyboard={updateKeyboard}/>
                            {
                                correct_answer == true ? 
                                    (<div className="form_msg form_success_msg">
                                        <p><strong>To get the Hacker Terminal secret code decipher this: Fqyx dqemy cl hidk, meifiq, hqemy, hwns, swzi, lqzmy.</strong></p>
                                    </div>)
                                : null
                            }
                            {
                                correct_answer == false ?
                                    (<div className="form_msg form_error_msg"><p>Code Incorrect</p></div>)
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default User;