import React, {useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import ScreenImg from "./assets/images/common-screen.png"
import DataImg from "./assets/images/data.png";
import DataSHImg from "./assets/images/data-sh.png";
import SuccessImg from "./assets/images/success-img.png";
import Audio from "./components/Audio";
import { useNavigate } from 'react-router-dom';
import EnglishKeyboard from './components/EnglishKeyboard';
import {saveToSessionStorage,loadFromSessionStorage,removeFromSessionStorage} from './helpers.js';

const Data = () => {

    const navigate = useNavigate();
    const [play_audio,set_play_audio] = useState(undefined);
    const [correct_answer,set_correct_answer] = useState(undefined);
    const [keyboardNumber,setKeyboardNumber] = useState('');
    const [page_loaded,set_page_loaded] = useState(false);
    const get_result_time = 500;
    const correct_code = 'alicek';
    let setTimer = undefined;
    const inputRef = useRef('');

    const input_focus = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        
        inputRef.current.focus({ preventScroll: true });
        inputRef.current.setSelectionRange(inputRef.current.value.toString().length,inputRef.current.value.toString().length);

        window.scrollTo(x, y);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // Optional: smooth scroll animation
        });
        if(loadFromSessionStorage('sap_data_answer') !== undefined && loadFromSessionStorage('sap_data_answer') !== null && loadFromSessionStorage('sap_data_answer') == correct_code)
        {
            set_correct_answer(true);
            setKeyboardNumber(correct_code);   
        }
        input_focus();
    },[]);

    useEffect(() => {
    },[correct_answer])

    const updateKeyboard = (key,number='') => {
        set_correct_answer(undefined);
        switch (key) {
            case 'text':
                setKeyboardNumber(keyboardNumber+number)
            break;

            case 'delete':
                let newkeyboardNumber = keyboardNumber.split('');
                newkeyboardNumber.pop();
                setKeyboardNumber(newkeyboardNumber.join(''));
            break;
        
            default:
                break;
        }
    }    

    useEffect(() => {
        input_focus();
        // set_correct_answer(undefined);
        clearTimeout(setTimer);
        set_play_audio(undefined);
        if(keyboardNumber.length >= correct_code.length)
        {
            setTimer = setTimeout(function(){
                if(keyboardNumber.toLowerCase() == correct_code.toLowerCase())
                {
                    set_correct_answer(true);
                    if(loadFromSessionStorage('sap_data_answer') == undefined || loadFromSessionStorage('sap_data_answer') == null)
                    {
                        set_play_audio('correct');
                    }
                    saveToSessionStorage('sap_data_answer',correct_code);
                }else{
                    if(keyboardNumber.toString().length >= correct_code.toString().length)
                    {
                        removeFromSessionStorage('sap_data_answer');
                        set_correct_answer(false);
                        if(loadFromSessionStorage('sap_data_answer') == undefined || loadFromSessionStorage('sap_data_answer') == null)
                        {
                            set_play_audio('incorrect');
                        }                        
                    }
                }
            },get_result_time);
        }
    },[keyboardNumber]);

    const handleKeyUp = () => {
        alert('handleKeyUp');
    }

    return(
        <>
            <Audio play_audio={play_audio} set_play_audio={set_play_audio}/>
            <div className="main_container">
                <div className="data_screen common_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_img">
                        <img src={ScreenImg} alt="ScreenImg"/>
                    </div>
                    <div className="screen_scroll">
                        <div className="screen_header">
                            <Link to="/lobby" className="back_btn">&lt;&lt;back</Link>
                            <div className="screen_header_logo">
                                <img src={DataSHImg} alt="DataImg" />
                            </div>
                        </div>
                        <div className="screen_content">
                            <p>1. The 'BTP Commercial and Management Services' are one of the critical _______ layers of the SAP BTP<br/>
                            2. The P in BTP<br/>
                            3. The B in LOB<br/>
                            4. The A in BAS<br/>
                            5. Application replacing HTML5Repo<br/>
                            6. BTP services empower external what?<br/></p>
                            <p>When all have been answered, enter password to access Secret Cloud.</p>
                        </div>
                        <div className="qwerty_keyboard_wrapper">
                            <div className="input_wrapper">
                                <input inputmode="none" ref={inputRef} type="text" name="number" value={keyboardNumber.toUpperCase()} className={correct_answer == true ? 'form-control success' : (correct_answer == false ? 'form-control error' : 'form-control')} onKeyDown={(e) => e.preventDefault()} onClick={(e) => {e.preventDefault();input_focus();}} autoComplete={0} onChange={() => {}}/>
                            </div>
                            <EnglishKeyboard updatekeyboard={updateKeyboard}/>
                            {
                                correct_answer == true ?
                                    (<div className="form_msg form_success_msg">
                                        <p><strong>Second half of Secret Cloud password is 0010 0111</strong></p>
                                    </div>)
                                : null

                            }
                            {
                                correct_answer == false ?
                                    (<div className="form_msg form_error_msg"><p>Code Incorrect</p></div>)
                                : null
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Data;