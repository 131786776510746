import React,{useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import ScreenImg from "./assets/images/common-screen.png"
import CloudImg from "./assets/images/cloud.png";
import CloudSHImg from "./assets/images/cloud-sh.png";
import LogoGrpImg from "./assets/images/logo-grp.png";
import LockImg from "./assets/images/lock.png";
import NumberKeyboard from "./components/NumberKeyboard";
import { useNavigate } from 'react-router-dom';
import Audio from "./components/Audio";
import {saveToSessionStorage,loadFromSessionStorage,removeFromSessionStorage} from './helpers.js';

const Cloud = () => {   
    const inputRef = useRef('');  
    const navigate = useNavigate();
    const [keyboardNumber,setKeyboardNumber] = useState('');
    const [correct_answer,set_correct_answer] = useState(undefined);
    const [play_audio,set_play_audio] = useState(undefined);
    let setTimer;
    const get_result_time = 500;
    const correct_code = 5827;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // Optional: smooth scroll animation
        });
        if(loadFromSessionStorage('sap_cloud_answer') !== undefined && loadFromSessionStorage('sap_cloud_answer') !== null && loadFromSessionStorage('sap_cloud_answer') == correct_code)
        {
            set_correct_answer(true);
            setKeyboardNumber(correct_code);   
        }
        input_focus();
    },[]);

    useEffect(() => {
        input_focus();
        clearTimeout(setTimer);
        // set_correct_answer(undefined);
        set_play_audio(undefined);
        if(keyboardNumber.length > 0)
        {
            setTimer = setTimeout(function(){
                if(keyboardNumber == correct_code)
                {
                    set_correct_answer(true);
                    if(loadFromSessionStorage('sap_cloud_answer') == undefined || loadFromSessionStorage('sap_cloud_answer') == null)
                    {
                        set_play_audio('correct');
                    }
                    saveToSessionStorage('sap_cloud_answer',correct_code);
                }else{
                    if(keyboardNumber.toString().length >= correct_code.toString().length)
                    {
                        removeFromSessionStorage('sap_cloud_answer',correct_code);                        
                        set_correct_answer(false);
                        if(loadFromSessionStorage('sap_cloud_answer') == undefined || loadFromSessionStorage('sap_cloud_answer') == null)
                        {
                            set_play_audio('incorrect');
                        }
                    }
                }

            },get_result_time);
        }
    },[keyboardNumber]);
    


    const updateKeyboard = (key,number='') => {
        clearTimeout(setTimer);
        set_correct_answer(undefined);
        set_play_audio(undefined);
        switch (key) {
            case 'number':
                setKeyboardNumber(keyboardNumber.toString()+number)
            break;

            case 'delete':
                let newkeyboardNumber = keyboardNumber.toString().split('');
                newkeyboardNumber.pop();
                setKeyboardNumber(newkeyboardNumber.join(''));
            break;
        
            default:
                break;
        }
    }

    const input_focus = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        
        inputRef.current.focus({ preventScroll: true });
        inputRef.current.setSelectionRange(inputRef.current.value.toString().length,inputRef.current.value.toString().length);

        window.scrollTo(x, y);
    }
  

    return(
        <>
            <Audio play_audio={play_audio} set_play_audio={set_play_audio}/>
            <div className="main_container">
                <div className="cloud_screen common_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_img">
                        <img src={ScreenImg} alt="ScreenImg"/>
                    </div>
                    <div className="screen_scroll">
                        <div className="screen_header">
                            <Link to="/lobby" className="back_btn">&lt;&lt;back</Link>
                            <div className="screen_header_logo">
                                <img src={CloudSHImg} alt="CloudImg" />
                            </div>
                        </div>
                        <div className="screen_content">
                            <p>Some info we gathered on SAP Cloud Virtualization: <br/>SAP’s Cloud Virtualization Group develops the vSMP MemoryONE virtual machine, which enables the transparent use of non-volatile memory (flash) as if it were DRAM, for main system memory, delivering similar performance and up to 40% lower TCO for infrastructure in the cloud.</p>
                            <p>vSMP MemoryONE is the infrastructure for running SAP’s HANA Cloud, saving SAP an 8 digit figure annually (and growing), and accelerating LoB adoption of HANA Cloud due to the attractive cost structure.</p>
                        </div>
                        <div className="number_keyboard_wrapper">
                            <div className="input_wrapper">
                                <input inputmode="none" type="text" name="number" value={keyboardNumber} className={correct_answer == true ? 'form-control success' : (correct_answer == false ? 'form-control error' : 'form-control')} ref={inputRef} onKeyDown={(e) => e.preventDefault()} onClick={(e) => {e.preventDefault();input_focus();}} autoComplete="off" />
                            </div>
                            <NumberKeyboard update_keyboard={updateKeyboard}/>
                            {
                                correct_answer == true ?
                                    (<>
                                        <div className="form_msg form_success_msg">
                                            <p><strong>Only the icons that are used as sample use cases should be used</strong></p>
                                        </div>
                                        <div className="cloud_img_wrapper">
                                            <img src={LogoGrpImg} alt="LogoGrpImg"/>
                                            <img src={LockImg} alt="LockImg"/>
                                        </div>
                                    </>)
                                : null
                            }
                            {
                                correct_answer == false ?
                                    (<div className="form_msg form_error_msg"><p>Code Incorrect</p></div>)
                                : null
                            }
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cloud;