import React,{useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";

import ScreenImg from "./assets/images/common-screen.png"
import SecretsImg from "./assets/images/secrets.png";
import SecretsSHImg from "./assets/images/secrets-sh.png";
import Audio from "./components/Audio";
import {saveToSessionStorage,loadFromSessionStorage,removeFromSessionStorage} from './helpers.js';

const Secrets = () => {    

    const [selected_anwers,set_selected_answers] = useState([]);
    const [is_correct_answer,set_is_correct_answer] = useState(undefined);
    const [play_audio,set_play_audio] = useState(undefined);
    const limit_max_answers = 3;
    const correct_answer = ["faster","smoother","easier"];
    const [page_loaded,set_page_loaded] = useState(false);
    useEffect(() => {
        let sap_secret_answer = loadFromSessionStorage('sap_secret_answer');
        if(sap_secret_answer !== undefined && sap_secret_answer !== null && arraysAreEqual(correct_answer,sap_secret_answer))
        {
            set_is_correct_answer(true);
            set_selected_answers(correct_answer);
        }
    },[]);

    const answerSelect = (e) => {
        if(e.target.checked == true)
        {
            if(selected_anwers.length < limit_max_answers)
            {
                selected_anwers.push(e.target.getAttribute('name'));
            
                set_selected_answers([...new Set(selected_anwers)]);
            }
        }else{
            let new_selected_anwers = selected_anwers.filter(item => item !== e.target.getAttribute('name'));
            set_selected_answers([...new Set(new_selected_anwers)]);
        }

        
    }

    useEffect(() => {
        if(selected_anwers.length == limit_max_answers)
        {
            if(arraysAreEqual(correct_answer,selected_anwers))
            {
                set_is_correct_answer(true);
                if(page_loaded == true)
                {
                    set_play_audio('correct');
                    
                }
                set_is_correct_answer(true);
                saveToSessionStorage('sap_secret_answer',correct_answer);
            }
            else{
                set_is_correct_answer(true);
                if(page_loaded == true)
                {
                    set_play_audio('incorrect');

                }
                set_is_correct_answer(false);
                removeFromSessionStorage('sap_secret_answer');
            }
        }else{
            removeFromSessionStorage('sap_secret_answer');
            set_play_audio(undefined);
            set_is_correct_answer(undefined);
        }
    },[selected_anwers,page_loaded]); 

    const handleCheckboxChange = () => {
        set_page_loaded(true);
    }

    function arraysAreEqual(arr1, arr2) {
        // Check if arrays have the same length
        if (arr1.length !== arr2.length) {
          return false;
        }
      
        // Check if all elements are identical and in the same order
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
            return false;
          }
        }
      
        return true;
      }

    return(
        <>
            <Audio play_audio={play_audio} set_play_audio={set_play_audio}/>
            <div className="main_container">
                <div className="user_screen common_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_img">
                        <img src={ScreenImg} alt="ScreenImg"/>
                    </div>
                    <div className="screen_scroll">
                        <div className="screen_header">
                            <Link to="/lobby" className="back_btn">&lt;&lt;back</Link>
                            <div className="screen_header_logo">
                                <img src={SecretsSHImg} alt="SecretsImg" />
                            </div>
                        </div>
                        <div className="screen_content">
                            <p>Some information we found on AP LCADT BAST product: SAP Build Code addresses the need for fusion development, offering AI-based productivity tools for developers and streamining collaboration with business experts who use SAP Build low-code solutions. The new solution, which is optimized for Java and JavaScript developers, also offers interoperability with ABAP Cloud as well as robust governance and lifecycle management capabilities. SAP Build Code provide a turn-key environment for coding, testing, integrations, and application lifecycle management.<br/> To access Secret Cloud press three correct buttons in the correct order</p>
                        </div>
                        <div className="secrets_checkbox_wrapper">
                            <div className="secrets_checkbox_row">
                                <div className="secrets_checkbox">
                                    <input id="checkbox-1" type="checkbox" onClick={(e) => answerSelect(e)} name="cooler" onChange={handleCheckboxChange} checked={selected_anwers.includes('cooler') ? true : false}/>
                                    <label htmlFor="checkbox-1">cooler</label>
                                </div>
                                <div className="secrets_checkbox">
                                    <input id="checkbox-2" type="checkbox" onClick={(e) => answerSelect(e)} name="smoother" onChange={handleCheckboxChange} checked={selected_anwers.includes('smoother') ? true : false}/>
                                    <label htmlFor="checkbox-2">smoother</label>
                                </div>
                                <div className="secrets_checkbox">
                                    <input id="checkbox-3" type="checkbox" onClick={(e) => answerSelect(e)} name="smarter" onChange={handleCheckboxChange} checked={selected_anwers.includes('smarter') ? true : false}/>
                                    <label htmlFor="checkbox-3">smarter</label>
                                </div>
                                <div className="secrets_checkbox">
                                    <input id="checkbox-4" type="checkbox" onClick={(e) => answerSelect(e)} name="easier" onChange={handleCheckboxChange} checked={selected_anwers.includes('easier') ? true : false}/>
                                    <label htmlFor="checkbox-4">easier</label>
                                </div>
                                <div className="secrets_checkbox">
                                    <input id="checkbox-5" type="checkbox" onClick={(e) => answerSelect(e)} name="harder" onChange={handleCheckboxChange} checked={selected_anwers.includes('harder') ? true : false}/>
                                    <label htmlFor="checkbox-5">harder</label>
                                </div>
                                <div className="secrets_checkbox">
                                    <input id="checkbox-6" type="checkbox" onClick={(e) => answerSelect(e)} name="better" onChange={handleCheckboxChange} checked={selected_anwers.includes('better') ? true : false}/>
                                    <label htmlFor="checkbox-6">better</label>
                                </div>
                                <div className="secrets_checkbox">
                                    <input id="checkbox-7" type="checkbox" onClick={(e) => answerSelect(e)} name="faster" onChange={handleCheckboxChange} checked={selected_anwers.includes('faster') ? true : false}/>
                                    <label htmlFor="checkbox-7">faster</label>
                                </div>
                                <div className="secrets_checkbox">
                                    <input id="checkbox-8" type="checkbox" onClick={(e) => answerSelect(e)} name="stronger" onChange={handleCheckboxChange} checked={selected_anwers.includes('stronger') ? true : false}/>
                                    <label htmlFor="checkbox-8">stronger</label>
                                </div>
                            </div>
                            {
                                is_correct_answer == true ?
                                    (<div className="form_msg form_success_msg">
                                        <p><strong>First half of Secret Cloud password is 0101 1000</strong></p>
                                    </div>)
                                : null
                            }
                            {
                                is_correct_answer == false ?
                                    (<div className="form_msg form_error_msg"><p>The sequence is not correct</p></div>)
                                : null
                            }                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Secrets;