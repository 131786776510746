import React from "react";
import { Link } from 'react-router-dom';
import ScreenImg from "./assets/images/start-screen.png"

const StartScreen = () => {
    return(
        <>
            <div className="main_container">
                <div className="start_screen screen_height screen_bg" style={{backgroundImage: `url(${ScreenImg})`}}>
                    <div className="screen_img">
                        <img src={ScreenImg} alt="ScreenImg"/>
                    </div>
                    <div className="screen_scroll">
                        <Link className="start_btn" to="/intro">Start</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StartScreen;